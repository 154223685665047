@-webkit-keyframes navBarCustomSlideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes navBarCustomSlideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.nbct_animate__slideInDown {
  -webkit-animation-name: navBarCustomSlideInDown;
  animation-name: navBarCustomSlideInDown;
}

@-webkit-keyframes navBarCustomFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes navBarCustomFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.nbct_animate__navBarCustomFadeInDown {
  -webkit-animation-name: navBarCustomFadeInDown;
  animation-name: navBarCustomFadeInDown;
}
